import "vanilla-cookieconsent";

function reload_page() {
    window.location.reload();
}

window.onload = function () {
    if (typeof KCCM_DATA !== "undefined") {
        let cc = initCookieConsent();
        window.kccm_cc = cc;

        let config = JSON.parse(KCCM_DATA.cc_settings);

        // Register events.
        config.onFirstAction = reload_page;
        config.onChange = reload_page;

        // Run and render vanilla-cookieconsent.
        cc.run(config);
        let opener_label = (KCCM_DATA.modal_opener_label) ? KCCM_DATA.modal_opener_label : "NastavenÃ­ cookies";
        let opener_pos = Number(KCCM_DATA.modal_opener_pos);
        opener_pos = (isNaN(opener_pos)) ? 2.5 : opener_pos;
        let enable_consent_close_btn = (KCCM_DATA.enable_consent_close_btn) ? Boolean(KCCM_DATA.enable_consent_close_btn) : false;

        /* jQuery context */
        (function ($) {
            // Render settings opener
            let opener_button = $(`<button id='kccm_settings_opener' class='kccm_settings_opener' type='button' aria-label='${opener_label}'></button>`);
            if (opener_pos > 50.0) {
                opener_button.css("right", Math.abs(opener_pos - 100.0) + "%");
            } else {
                opener_button.css("left", opener_pos + "%");
            }
            $("body").append(opener_button);
            opener_button.on("click", function (e) {
                e.preventDefault();
                cc.showSettings(0);
            });

            // Add consent modal close button
            if (enable_consent_close_btn) {
                let consent_modal = $("div.box#cm");
                let close_btn = $("<button id='consent-c-bn' type='button' aria-label='" + config.languages['universal'].settings_modal.close_btn_label + "'>");
                consent_modal.append(close_btn);
                close_btn.on("click", function (e) {
                    e.preventDefault();
                    cc.hide();
                    cc.accept([]);
                });
            }
        })(jQuery);
    }
}